import { observer } from "mobx-react-lite"
import { useState } from "react"
import TitleEditor from "../edit/TitleEditor"
import BodyEditor from "../edit/BodyEditor"
import { Button } from "../../../components/shadcn/ui/button"
import { useStore } from "../../../code/api/state/store"
import { Plus } from "lucide-react"

export default observer(function CreateBlog() {
    const [titleSt, setTitleSt] = useState<string>("")
    const [bodySt, setBodySt] = useState<string>("")
    
    const {blogStore: {createBlog}} = useStore()

    const create = () => {
        createBlog({title: titleSt, body: bodySt})
    }

    return (
        <div className='mt-16 space-y-5'>
            <TitleEditor
                setValue={setTitleSt}
                value={titleSt}
            />
            <BodyEditor 
                setValue={setBodySt}
                value={bodySt}
            />
            <Button 
                onClick={create} 
                variant='outline' 
                className='hover:bg-bgGray'
            >
                <Plus size='20' className='mr-1' />
                Create
            </Button>
        </div>
    )
})