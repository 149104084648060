import { Skeleton } from "../../../components/shadcn/ui/skeleton";

export default function BlogDetailsSkeleton() {
    return (
        <div className='w-full mt-10'>
            <div className='flex justify-between items-center'>
                <Skeleton className='w-52 h-7' />
            </div>
            {/* Display details */}
            <Skeleton className='w-32 h-5 mt-2' />
            {/* Display post-body */}
            <Skeleton className='w-full h-5 mt-7' />
            <Skeleton className='w-full h-5 mt-2' />
            <Skeleton className='w-full h-5 mt-2' />
            <Skeleton className='w-full h-5 mt-2' />
            <Skeleton className='w-full h-5 mt-2' />
        </div>
    )
}