import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from "mobx-react-lite"
import { z } from "zod"
import { useForm } from 'react-hook-form'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '../../components/shadcn/ui/form';
import { Input } from '../../components/shadcn/ui/input'
import { Button } from '../../components/shadcn/ui/button'
import { useStore } from '../../code/api/state/store';
import { toast } from '../../components/shadcn/toast/use-toast';

export default observer(function Login() {
    const {userStore: {login, loading}} = useStore()

    const formSchema = z.object({
        email: z.string().email("Input has to be an email."),
        password: z.string()
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
            password: ''
        }
    })

    function onSubmit(values: z.infer<typeof formSchema>) {
        login({
            email: values.email,
            password: values.password
        })
    }

    return (
        <div className='flex mt-[40%]'>
            <div className='h-fit w-full max-w-96 px-5 m-auto'>
                <h1 className='text-center w-full font-semibold tracking-wider text-lg mb-5'>Login</h1>
                <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                            <FormLabel>E-Mail</FormLabel>
                            <FormControl>
                                <Input placeholder="admin@aon.at" type='email' {...field} />
                            </FormControl>
                            <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                                <Input type='password' {...field} />
                            </FormControl>
                            <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className='flex justify-center'>
                        {loading ? (
                            <>
                                loading...
                            </>
                        ) : (
                            <Button 
                                type="submit"
                                className='hover:bg-gray-400/15 rounded-xl animate-all duration-300'
                            >
                                Submit
                            </Button>
                        )}
                    </div>
                </form>
                </Form>
            </div>
        </div>
    )
})