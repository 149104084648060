import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import 'react-quill/dist/quill.snow.css'
import { Link, useParams } from "react-router-dom"
import { useStore } from "../../../code/api/state/store"
import BodyEditor from "./BodyEditor"
import TitleEditor from "./TitleEditor"
import { Button } from "../../../components/shadcn/ui/button"
import { ArrowLeftIcon, SendIcon } from "lucide-react"
import Loader from "../../../components/common/Loader"

export default observer(function BlogEdit() {
    const [titleSt, setTitleSt] = useState<string | undefined>("")
    const [bodySt, setBodySt] = useState<string | undefined>("")
    const {id} = useParams()
    const {blogStore: {selectedBlog, loadBlog, loading, updateBlog}} = useStore()

    const update = () => {
        updateBlog({
            id: selectedBlog!.id,
            title: titleSt!,
            body: bodySt!
        })
    }

    useEffect(() => {
        if (!selectedBlog)
            loadBlog(id!)
        setTitleSt(selectedBlog?.title)
        setBodySt(selectedBlog?.body)
    }, [selectedBlog, loadBlog])

    if (loading || !selectedBlog) return <Loader />

    return (
        <div className='mt-10 space-y-5'>
            <Link
                to={`/blogs/view/${selectedBlog!.id}`}
                className='text-sm flex items-center hover:text-textGray transition-colors duration-300'
            >
                <ArrowLeftIcon size='18' className='mr-1' />
                go back
            </Link>
            <TitleEditor 
                value={titleSt}
                setValue={setTitleSt}
            />
            <BodyEditor
                value={bodySt}
                setValue={setBodySt}
            />
            <Button 
                onClick={update}
                variant='outline'
                className='hover:bg-bgGray'
            >
                <SendIcon size='16' className='mr-1' />
                Submit
            </Button>
        </div>
    )
})