import { makeAutoObservable, runInAction } from "mobx";
import { User } from "../../models/user/user";
import { LoginDto } from "../../models/user/loginDto";
import agent from "../agent";
import { store } from "./store";

export default class UserStore {
    user: User | null = null;
    loading = false;

    constructor(){
        makeAutoObservable(this)
    }

    get IsLoggedIn(){
        return !!this.user
    }

    login = async (creds: LoginDto) => {
        this.setLoading(true);
        try {
            const user = await agent.Account.login(creds)
            console.log(user)
            store.commonStore.setToken(user.token)
            runInAction(() => this.user = user)
            console.log(this.user)
            window.location.href = '/'
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    }

    logout = () => {
        store.commonStore.setToken(null)
        this.user = null
        window.location.href = '/'
    }

    getUser = async () => {
        this.setLoading(true)
        try {
            const user = await agent.Account.current()
            runInAction(() => this.user = user)
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    }

    setLoading = (state: boolean) => {
        this.loading = state;
    } 
}