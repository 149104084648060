import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { useStore } from '../code/api/state/store';
import { observer } from 'mobx-react-lite';
import Navbar from '../components/layout/Navbar';
import BlogDashboard from './blog/list/BlogDashboard';
import BlogDetails from './blog/details/BlogDetails';
import BlogEdit from './blog/edit/BlogEdit';
import login from './auth/login';
import logout from './auth/logout';
import NotFound from './common/NotFound';
import CreateBlog from './blog/create/CreateBlog';
import PrivateRoute from '../components/layout/PrivateRoute';

export default observer(function App() {
  const {commonStore, userStore} = useStore()


  useEffect(() => {
    if (commonStore.token)
      userStore.getUser().finally(() => commonStore.setAppLoaded())
    else
      commonStore.setAppLoaded()
  }, [commonStore, userStore])

  // if (!commonStore.appLoaded) return <h1>Loading app..</h1>

  return (
    <div className='max-w-xl mx-auto px-2'>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' Component={BlogDashboard} />
          <Route path='/create' element={<PrivateRoute Component={CreateBlog} />} />
          <Route path='/blogs/view/:id' Component={BlogDetails} />
          <Route path='/blogs/edit/:id' Component={BlogEdit} />
          <Route path='/login' Component={login} />
          <Route path='/logout' Component={logout} />
          <Route path='*' Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </div>
  );
})
