import { makeAutoObservable, runInAction } from "mobx";
import BlogPost, { CreateBlogPost, UpdateBlogPost } from "../../models/blogPost";
import agent from "../agent";
import { toast } from "../../../components/shadcn/toast/use-toast";
import { store } from "./store";


export default class BlogStore {
    selectedBlog: undefined | BlogPost = undefined
    blogs: BlogPost[] = []
    loading = false
    loadingInitial = false

    constructor() {
        makeAutoObservable(this)
    }

    loadBlogs = async () => {
        this.loadingInitial = true
        try {
            const result = await agent.Blog.list() 
            runInAction(() => this.blogs = result)
        } catch (error) {
            console.log(error)
        }
        this.setLoadingInitial(false)
    }

    loadBlog = async (id: string) => {
        this.loading = true
        try {
            const result = await agent.Blog.details(id)
            runInAction(() => this.selectedBlog = result)
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    }

    updateBlog = async (blog: UpdateBlogPost) => {
        this.loading = true
        try {
            await agent.Blog.update(blog)
            runInAction(() => {
                this.selectedBlog!.title = blog.title
                this.selectedBlog!.body = blog.body
            })
            toast({description: 'Successfully updated blog!'})
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    }

    createBlog = async (blog: CreateBlogPost) => {
        this.loading = true
        try {
            //create blog
            const result = await agent.Blog.create(blog)
            const newBlog = new BlogPost({
                id: result,
                title: blog.title,
                body: blog.body,
                created: new Date(),
                user: store.userStore.user!
            })

            //add blog to blogs array
            runInAction(() => {
                this.blogs = [...this.blogs, newBlog]
            })

            //redirect & inform user
            window.location.href = `/blogs/view/${result}`
            toast({description: 'Successfully created blog!'})
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    }

    deleteBlog = async (id: string) => {
        this.loading = true
        try {
            await agent.Blog.delete(id)
            
            //Delete selected blog
            runInAction(() => this.selectedBlog = undefined)
            
            //Delete blog from blogs array
            if (this.blogs.filter(x => x.id === id).length > 0) {
                runInAction(() => {
                    this.blogs = this.blogs.filter(x => x.id !== id)
                })  
            }

            //redirect & inform user
            window.location.href = '/'
            toast({description: 'Successfully deleted blog!'})
        } catch (error) {
            console.log(error)
        }
        this.setLoading(false)
    
    }

    setLoading = (state: boolean) => {
        this.loading = state
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }
}