import { TrashIcon } from 'lucide-react';
import Modal from 'react-modal'

interface Props {
    showAlert: boolean;
    setShowAlert: (v: boolean) => void;
    deleteAction: () => void; 
}

export default function DeleteFileAlert({showAlert, setShowAlert, deleteAction}: Props) {
    return (
        <Modal 
            isOpen={showAlert}
            className='bg-white rounded-xl text-black w-fit px-10 py-5 mx-auto'
            style={{content: {marginTop: '20%'}}}
        >
            <h1 className='text-black text-xl font-semibold'>Delete</h1>
            <p className='text-black mt-1 mb-10'>Are you sure about deleting this post?</p>
            <div className='flex justify-end space-x-5'>
                <button
                    className='flex items-center bg-red-600 text-white rounded-xl px-3 py-1 
                            hover:bg-red-500 transition-all duration-300 hover:shadow-xl'
                    onClick={() => deleteAction()}
                >
                    <TrashIcon size={20} />
                    Delete
                </button>
                <button
                    className='bg-gray-600 rounded-xl text-white px-3 py-1 
                            hover:bg-gray-500 transition-all duration-300 hover:shadow-xl'
                    onClick={() => setShowAlert(false)}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    )
}