import sanitizeHtml from 'sanitize-html'

interface Props {
    content: string
    className?: string
}

export default function DisplayHtml({content, className}: Props) {
    const sanitizedHtml = sanitizeHtml(content, {
        allowedTags: ['p', 'strong', 'em', 'a', 'u', 'br', 'h1', 'img', 'li', 'ul', 'ol'],
        allowedAttributes: {
            a: ['href'],
            img: [ 'src' ]
        },
        selfClosing: [ 'img', 'br' ]
    })

    return (
        <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} className={className}></div>
    )
}