import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { useStore } from "../../code/api/state/store"
import AdminLinks from "./AdminLinks"

export default observer(function Navbar() {
    const {userStore: {IsLoggedIn}} = useStore()

    return (
        <nav className='flex items-end'>
            <Link 
                className='mx-auto text-2xl tracking-wider font-semibold
                    hover:text-textGray mt-2 transition-colors duration-300'
                to="/"
            >
                anart1c
            </Link>
            {IsLoggedIn && 
              <AdminLinks />}
        </nav>
    )
})