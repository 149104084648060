import axios, { AxiosError, AxiosResponse } from "axios";
import BlogPost, { CreateBlogPost, UpdateBlogPost } from "../models/blogPost";
import { LoginDto } from "../models/user/loginDto";
import { User } from "../models/user/user";
import { toast } from "../../components/shadcn/toast/use-toast";
import { store } from "./state/store";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) 
        config.headers!.Authorization = `Bearer ${token}`;
    return config;
})

axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') 
        await sleep(1000);

    return response
}, (error: AxiosError) => {
    const {data, status, config} = error.response!;
    switch (status) {
        case 400:
            if (typeof data === 'string')
                toast({
                    variant: 'destructive',
                    description: data
                });
            //@ts-ignore
            if (config.method === 'get' && data.errors.hasOwnProperty('id'))
                window.location.href = '/404'
            //@ts-ignore
            if (data.errors) { //Validation error
                const modalStateErrors = [];
                //@ts-ignore
                for (const key in data.errors) {
                    //@ts-ignore
                    if (data.errors[key]) {
                        //@ts-ignore
                        modalStateErrors.push(data.errors[key])
                    }
                }
                console.log(modalStateErrors)
                for(const error in modalStateErrors) {
                    toast({variant: 'destructive', description: error})
                }
            }
            break;
        case 401: case 403:
            toast({variant: 'destructive', description: 'unauthorized'});
            break;
        case 404:
            window.location.href = '/404'
            break;
    }
    return Promise.reject(error)
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: LoginDto) => requests.post<User>('/account/login', user)
}

const Blog = {
    create: (blog: CreateBlogPost) => requests.post<string>('/blog/create', blog),
    list: () => requests.get<BlogPost[]>('/blog/list'),
    details: (id: string) => requests.get<BlogPost>(`/blog/details/${id}`),
    update: (blog: UpdateBlogPost) => requests.put<void>('/blog/update', blog),
    delete: (id: string) => requests.del<void>(`/blog/delete/${id}`)
}

const agent = {
    Account,
    Blog
}

export default agent;