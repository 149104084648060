import { observer } from "mobx-react-lite";
import { useStore } from "../../code/api/state/store";
import { Navigate } from "react-router-dom";

interface Props {
    Component: React.FC;
}

export default observer(function PrivateRoute({ Component }: Props) {
    const {userStore:{IsLoggedIn}} = useStore()

    return IsLoggedIn 
        ? <Component />   
        : <Navigate to='/login' />;
})