import { LogOut, MenuIcon, Plus } from "lucide-react"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from '../../components/shadcn/ui/dropdown-menu'
import { Link } from "react-router-dom"
import { useStore } from "../../code/api/state/store"

export default function AdminLinks() {
    const {userStore: {logout}} = useStore()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <MenuIcon className='hover:cursor-pointer hover:text-textGray transition-colors duration-300' />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-bgGray shadow-2xl">
                <DropdownMenuLabel>Links</DropdownMenuLabel>
                <DropdownMenuSeparator />

                {/* Create new Blog post */}
                <Link to='/create'>
                    <DropdownMenuItem>
                            <Plus className="mr-2 h-4 w-4" />
                            <span>Create Post</span>
                    </DropdownMenuItem>
                </Link>

                {/* Logout */} 
                <DropdownMenuItem onClick={() => logout()}>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}