import { useEffect } from "react"
import { store, useStore } from "../../../code/api/state/store"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import Biography from "./Biography"
import LoadingSkeleton from "./LoadingSkeleton"

export default observer(function BlogDashBoard(){
    const {blogStore: {blogs, loadingInitial, loadBlogs}, userStore} = useStore()

    useEffect(() => {
        if (blogs.length === 0)
            loadBlogs()
    }, [blogs.length, loadBlogs])

    return (
        <div>
            <Biography />
            <h2 className='font-semibold tracking-wider mt-6'>posts:</h2>
            {loadingInitial ? (
                <LoadingSkeleton />
            ) : (
                blogs.map((e, i) => (
                    <p className='text-gray-500' key={i}>
                        {new Intl.DateTimeFormat('en-US', {month: 'short', day: '2-digit', year: 'numeric'}).format(new Date(e.created))}
                        ‎ - ‎
                        <Link
                            to={`/blogs/view/${e.id}`} 
                            className='text-white tracking-wider transition-all underline duration-300 hover:text-textGray'
                        >
                            {e.title}
                        </Link>
                    </p>
                ))
            )}
        </div>
    )
})