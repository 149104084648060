import { createContext, useContext } from "react"
import BlogStore from "./blogStore"
import UserStore from "./userStore"
import CommonStore from "./commonStore"

interface Store {
    blogStore: BlogStore,
    userStore: UserStore,
    commonStore: CommonStore
}

export const store: Store = {
    blogStore: new BlogStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore()
}

export const StoreContext = createContext(store)

export function useStore() {
    return useContext(StoreContext)
}