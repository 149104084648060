import QuillEditor from 'react-quill'

interface Props {
    value: string | undefined
    setValue: (v: string) => void
}

export default function BodyEditor({value, setValue}: Props) {
    return (
        <QuillEditor 
            theme='snow'
            value={value}
            onChange={(v) => setValue(v)}
            modules={{
                toolbar: {
                    container: [
                        [{'header': [1,2,3]}],
                        ['bold', 'italic', 'underline'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['link', 'image']
                    ]
                }
            }}
        />
    )
}