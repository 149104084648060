import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../code/api/state/store";

export default observer(function Logout() {
    const {userStore: {IsLoggedIn}, commonStore: {setToken}} = useStore()

    useEffect(() => {
        if (IsLoggedIn)
            setToken(null)
        window.location.href = '/'
    }, [])

    return null
})