import ReactDOM from 'react-dom/client';
import App from './app/App';
import { StoreContext, store } from './code/api/state/store';
import { Toaster } from './components/shadcn/toast/toaster';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StoreContext.Provider value={store}>
    <App />
    <Toaster />
  </StoreContext.Provider> 
);