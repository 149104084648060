import { User } from "./user/user";

export default interface BlogPost {
    id: string;
    user: User;
    title: string;
    body: string;
    created: Date;
    updated?: Date;
}

export default class BlogPost implements BlogPost {
    constructor(post: BlogPost) {
        this.id = post.id
        this.user = post.user
        this.title = post.title
        this.body = post.body
        this.created = post.created
        this.updated = post.updated
    }
}

export interface CreateBlogPost {
    title: string;
    body: string;
}

export interface UpdateBlogPost extends CreateBlogPost {
    id: string;
}