import { observer } from "mobx-react-lite"
import { useStore } from "../../../code/api/state/store"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { DisplayDate } from "../../../code/helpers/date"
import DisplayHtml from "../../../code/helpers/displayHtml"
import { EditIcon, TrashIcon } from "lucide-react"
import DeleteFileAlert from "./DeleteFIleAlert"
import BlogDetailsSkeleton from "./BlogDetailsSkeleton"

export default observer(function BlogDetails() {
    const {id} = useParams()
    const {blogStore: {selectedBlog, loadBlog, loading, deleteBlog}, userStore: {IsLoggedIn}} = useStore()
    
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (!selectedBlog || selectedBlog.id !== id)
            loadBlog(id!)
    }, [selectedBlog, loadBlog])

    if (loading || !selectedBlog) return <BlogDetailsSkeleton />

    return (
        <div className='w-full mt-10'>
            <div className='flex justify-between items-center'>
                <h1 className='text-xl tracking-wider font-semibold'>{selectedBlog.title}</h1>
                {IsLoggedIn && (
                    <div className='flex space-x-5 justify-end'>
                        {/* Edit Link */}
                        <Link to={`/blogs/edit/${selectedBlog.id}`} className='ml-2'>
                            <EditIcon className='hover:text-green-500 transition-colors duration-300' size={20} />
                        </Link>
                        {/* Delete Button */}
                        <button
                            className='hover:text-red-500 transition-colors duration-300'
                            onClick={() => setShowAlert(true)}
                        >
                            <TrashIcon size={20} />
                        </button>
                    </div>
                )}
            </div>
            {/* Display details */}
            <p className='font-light mt-1 text-textGray'>{DisplayDate(selectedBlog.created)}</p>
            {/* Display post-body */}
            <DisplayHtml 
                content={selectedBlog.body} 
                className='mt-7' 
            />
            {/* Display modal for deleting blog post */}
            <DeleteFileAlert 
                showAlert={showAlert && IsLoggedIn} 
                setShowAlert={() => setShowAlert(false)} 
                deleteAction={() => deleteBlog(selectedBlog.id)}
            />
        </div>
    )
})