import { Input } from "../../../components/shadcn/ui/input"

interface Props {
    value: string | undefined
    setValue: (v: string) => void
}

export default function TitleEdit({value, setValue}: Props) {
    return (
        <Input value={value} onChange={(e) => setValue(e.currentTarget.value)} />
    )
}