export default function Biography() {
    return (
        <p className='mt-12'>
            I'm a 20 y/o programmer from Vienna 🇦🇹. <br/>
            I currently work full time while also studying software engineering.
            When I've got free time (rarely), I spend it with:
            <ul className='list-disc ml-5'>
                <li>programming</li>  
                <li>books</li>
                <li>sports</li>
            </ul> 
            <br />
            I'm very open about meeting new people, feel free to reach out!
            <br />
            <a href="https://X.com/anart1c" className='underline hover:text-textGray transition-all duration-300'>X profile</a>
        </p>
    )
}